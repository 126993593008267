import React, { FormEvent, useState } from 'react'
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { MoneyIcon } from '../../icons'
import { LoadingBackdrop } from '../../components'
import { config } from '../../config'
import { InitializeDepositRequest } from '../../services/deposit/models'

import { useNavigate } from 'react-router-dom'
import { useDeposit } from '../../hooks/use-deposit/use-deposit'
import { useAuth } from '../../hooks'

const useStyles = makeStyles(() => ({
  roundedAlert: {
    borderRadius: 12,
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.875rem',
    marginTop: '10px',
  },
}))

export const DepositPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { maxDepositAmount, initializeDeposit, error, loading } = useDeposit()
  const { logout } = useAuth()
  const classes = useStyles()

  const [amount, setAmount] = useState('')
  const [email, setEmail] = useState('')

  const [submitError, setSubmitError] = useState(false)
  const [inputErrors, setInputErrors] = useState<{
    email?: string
    amount?: string
  }>({})

  React.useEffect(() => {
    if (error) {
      if (error.status === 401) {
        logout()
        navigate('/error')
      }
    }
  }, [error])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const emailError = validateEmail(email)
      const amountError = amount
        ? validateAmount(parseFloat(amount))
        : t('depositPage.error.amountEmpty') + maxDepositAmount + '€'

      if (email) {
        setInputErrors(prev => ({
          ...prev,
          email: emailError,
        }))
      }
      if (amount) {
        setInputErrors(prev => ({
          ...prev,
          amount: amountError,
        }))
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [email, amount])

  const validateEmail = (value: string): string => {
    return !value || !/\S+@\S+\.\S+/.test(value)
      ? t('depositPage.error.email')
      : ''
  }

  const validateAmount = (value: number): string => {
    if (value <= 0) return t('depositPage.error.amountNegative')
    if (value > maxDepositAmount)
      return t('depositPage.error.amountTooLarge') + maxDepositAmount + '€'
    return ''
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (loading) return

    if (maxDepositAmount <= 0) {
      return
    }

    const emailError = validateEmail(email)
    const amountError = amount
      ? validateAmount(parseFloat(amount))
      : t('depositPage.error.amountEmpty') + maxDepositAmount + '€'

    if (emailError || amountError) {
      setInputErrors({ email: emailError, amount: amountError })
      setSubmitError(true)
      return
    }

    const params: InitializeDepositRequest = {
      email: email,
      amount: parseFloat(amount) * 100,
      redirectUrls: {
        success: window.location.origin + config.appRoutes.success,
        cancel: window.location.origin + config.appRoutes.cancel,
      },
    }

    initializeDeposit(params).then(res => {
      window.location.replace(res.data.href)
    })
  }

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setter(value)
      setSubmitError(false)
    }

  return (
    <>
      <h2>{t('depositPage.title')}</h2>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity='info' classes={{ root: classes.roundedAlert }}>
              {t('depositPage.info')}
            </Alert>
          </Grid>
          {maxDepositAmount <= 0 && (
            <Grid item xs={12}>
              <Alert severity='error' classes={{ root: classes.roundedAlert }}>
                {t('depositPage.error.ownWalletFull')}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('common.email')}
              name='email'
              variant='outlined'
              required
              type='email'
              disabled={loading}
              value={email}
              onChange={handleInputChange(setEmail)}
              error={!!inputErrors.email}
              helperText={inputErrors.email || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name='amount'
              label={t('common.amount')}
              helperText={
                inputErrors.amount ||
                `${t('depositPage.amountHelper')}${maxDepositAmount}€`
              }
              error={!!inputErrors.amount}
              value={amount}
              onChange={handleInputChange(setAmount)}
              required
              type='number'
              variant='outlined'
              disabled={loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <MoneyIcon opacity='0.25' />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                inputProps: {
                  max: maxDepositAmount,
                  step: '0.01',
                },
              }}
            />
          </Grid>
          {submitError && (
            <Grid item xs={12}>
              <div className={classes.errorMessage}>
                {t('depositPage.error.generic')}
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              color='primary'
              type='submit'
              variant='contained'
              disabled={loading || maxDepositAmount <= 0}
            >
              {t('common.button.confirm')}
            </Button>
          </Grid>
        </Grid>
      </form>

      <LoadingBackdrop open={loading} />
    </>
  )
}

export default DepositPage
